import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import logo from '../../assets/images/landingpage2.png';
import { POST } from '../../api/intro';
import 'react-toastify/dist/ReactToastify.min.css';
import './LandingPage.css';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVal: '',
      class: ''
    };
    if (isMobile) {
      this.state.class = 'accessTextMobile';
    } else {
      this.state.class = 'accessText';
    }
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%'}}>
        <img
          src={logo}
          className='image1'
          alt='RewardCoin'
        />

        <h3 className={this.state.class}>Enter access code below to view our introductory video</h3>

        <div className='access'>
          <input
            className='accessInput'
            id='input' type='text'
            value={this.state.inputVal}
            onChange={e => this.updateVal(e)}
          />
          <button className='accessBtn' type='button' onClick={this.validate}>Submit</button>
        </div>
      </div>
    );
  }

  updateVal = (event) => {
    this.setState({
      inputVal: event.target.value
    });
    console.log(this.state.inputVal);
  }

  validate = async () => {
    console.log(this.state.inputVal);
    var input = {
      "access": this.state.inputVal
    };
    var result = await POST(input);
    console.log(result.body);
    if (result.body === 0) {
      this.displayInvalid();
    } else {
      this.props.mainState.setPrev(this.props.mainState.selectedPage);
      this.props.mainState.selectPage('video');
    }
  }

  displayInvalid = () => {
    toast.error('Invalid Access Code', {
      autoClose: 2000,
      className: 'invalid'
    });
  }
}

export default LandingPage;
