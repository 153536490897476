import { API } from 'aws-amplify';

const api = 'website-main';
const path = '/video';

export const POST = (req) => {
  return new Promise(resolve => {
    console.log(req);
    API.post(api, path, {
      body: req
    }).then(res => {
      resolve(res);
    }).catch(error => {
      console.log(error.response);
      console.log(error);
      resolve(error.response);
    });
  });
}
