// Modules
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { observer } from 'mobx-react';
import './App.css';

// State Data
import mainState from './stateData/MainState';

// Pages
import LandingPage from './pages/Landing/LandingPage';
import VideoPage from './pages/Video/VideoPage';

// Constants
const SCREENS = {
  landing: <LandingPage mainState={mainState} />,
  video: <VideoPage mainState={mainState} />
}

class App extends Component {
  render() {
    return (
      <div className="app">

        <div className="page">
          {SCREENS[mainState.selectedPage]}
        </div>

        <ToastContainer closeButton={false} hideProgressBar={true}/>
      </div>
    );
  }
}

export default observer(App);
