import React, { Component } from 'react';
import video from '../../assets/111IntroVid789.mp4';
import logo from '../../assets/images/landingpage2.png';
import './VideoPage.css';

class VideoPage extends Component {

  render() {
    return (
      <div style={{ height: '100%', width: '100%'}}>
        <img
          src={logo}
          className='image2'
          alt='RewardCoin'
        />

        <video className='video' autoplay={false} controls='true'>
          <source type='video/mp4' src={video}/>
        </video>
      </div>
    );
  }


}

export default VideoPage;
