// State to manage overall site

import { decorate, observable } from 'mobx';

class MainState {

  selectedPage = 'landing';
  prevPage = 'landing';
  landing = true;
  video = false;

  setPrev(page) {
    this.prevPage = page;
  }

  selectPage(page) {
    console.log(page);
    this.selectedPage = page;
    console.log(this.selectedPage);
  }

  changePage(next) {
    switch (this.selectedPage) {
      case 'landing': {
        this.landing = false;
        this[next] = true;
        break;
      }
      case 'video': {
        this.video = false;
        this[next] = true;
        break;
      }
      default: {
        console.log('didnt find');
      }
    }
  }
}

decorate(MainState, {
  selectedPage: observable,
  prevPage: observable,
  landing: observable,
  video: observable
});

const mainState = new MainState();
export default mainState;
